<template>
    <div>
        <av-payment-method-credit-card
            v-show="!isCreatingTransaction"
            ref="jest-authvia-component"
            id="avcc"
            :data-token="tokenValue"
            :data-azp="merchantClientID"
            data-submit-label="Place Order">
        </av-payment-method-credit-card>

        <h1 v-show="isCreatingTransaction">Creating Transaciton...</h1>
    </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex';

export default {
	name: 'AuthviaCreditCard',

	computed: {
		...mapState('authvia', ['tokenValue', 'merchantClientID', 'isCreatingTransaction'])
	},

	methods: {
		...mapMutations('authvia', ['setPaymentMethodSuccess', 'setIsCreatingTransaction'])
	},

	mounted() {
		const authViaWidget = document.getElementById('avcc');

		if (authViaWidget) {

			/**
			 * Fires once all fields have been populated
			 */
			authViaWidget.addEventListener('submit', (e) => {
				console.log('submit: ', e);
			});

			/**
			 * Fires if any error happens within the form. (IE: Required feild not filled out)
			 */
			authViaWidget.addEventListener('error', (e) => {
				console.log('error: ', e);
			});

			/**
			 * When the form is submitted with a successful response form Authvia.
			 */
			authViaWidget.addEventListener('success', (paymentMethod) => {
				this.setIsCreatingTransaction(true);

				const paymentDetails = paymentMethod.detail[0];

				this.setPaymentMethodSuccess(paymentDetails);

				console.log('Payment method', paymentDetails);
				this.$emit('paymentMethodSuccess', paymentDetails);
				// TODO: send request to minilith with our payment information

				// TODO: Charge the card with information from our last minilith call

				// TODO: Send request to minilith to see if the charge was successful?
			});
		}


	}
};
</script>
