import axios from 'axios';
import { every } from 'underscore';
import {
	dealerImpression, dealerChooseSuccess, dealerChooseAlert,
	dealerEnterZipAlert, dealerEnterZipSuccess
} from '@/utils/setDataLayer';

// Will also need to set the dealership information in redis so we can grab it
// The key will be the dealer UID, then store the rest. Session will more than likely not be the right place
// And it should be backend nonsense, none of this front end client garbage. Store for a day?
export const select = async ({ commit, state }, { uid }) => {
	commit('loading', true);

	let dealerSelected = state.locationList.find(dealer => dealer.websiteUID === uid);
	let { data, error } = await axios.get('/service/dealerDetails', { params: { websiteUID: uid }});

	if (error) {
		commit('selected', []);
		// Sets dataLayer
		dealerChooseAlert({
			alertCategory: 'No Dealer',
			alertMessage: 'Dealer is not available'
		});
		throw new Error(error);
	}

	dealerSelected = data;
	commit('selected', dealerSelected);
	commit('search/setDealershipId', dealerSelected.dealershipid, { root: true });

	// Sets dataLayer, dealerAmenities check is necessary because on refresh of app/product it is not available
	dealerChooseSuccess({
		dealerZip: dealerSelected.zip,
		dealerAmenities: dealerSelected.amenities ? dealerSelected.amenities : false,
		dealerCode: dealerSelected.code
	});

	commit('loading', false);
};

export const locationListRequest = async ({ commit, state }, payload) => {
	const { data: dealers, error } = await axios.get('/service/dealerLocationListRequest', {
		params: payload
	});

	if (!dealers.length || error) {
		commit('loading', false);
		commit('locationList', []);
		dealerEnterZipAlert({
			alertUserEntry: state.zipCode,
			alertMessage: 'No Dealers',
			alertCategory: 'Out of Market'
		});
	} else {
		dealerEnterZipSuccess({
			location: state.zipCode,
			dealerRadius: state.defaultZipRadius
		});
	}

	const first25Dealers = (dealers).slice(0, 25);

	commit('locationList', first25Dealers);
};
export const dealerLocationListRequest = async ({ commit, state }, { zipCode, latitude, longitude}) => {
	commit('loading', true);
	const { data: dealers, error } = await axios.get('/service/dealerLocationListRequest', {
		params: { pattern: zipCode,
			latitude,
			longitude }
	});

	if (!dealers.length || error) {
		commit('loading', false);
		commit('locationList', []);

		return dealerEnterZipAlert({
			alertUserEntry: state.zipCode,
			alertMessage: 'No Dealers',
			alertCategory: 'Out of Market'
		});
	}
	dealerEnterZipSuccess({
		location: state.zipCode,
		dealerRadius: state.defaultZipRadius
	});

	const first25Dealers = (dealers).slice(0, 25);

	commit('locationList', first25Dealers);

	return commit('loading', false);

};

export const allDetails = ({ commit }, dealers) => { // eslint-disable-line complexity
	const hasDetails = every(dealers, d => {
		return d.hasOwnProperty('amenities') && d.hasOwnProperty('hoursOfOperation');
	});

	if (hasDetails) {
		return commit('showing', dealers);
	}

	commit('loading', false);
	commit('showing', dealers);

	return dealerImpression(dealers);
};
