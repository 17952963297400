<template>
    <div class="div">
        <h2>{{$t('message.thankyou')}}</h2>
        <p>{{$t('message.tireExpert')}} {{vehicleInfo}}.</p>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import { RudderstackChatMixin } from '@/rudderstack/track/mixins/chat';

export default {
	props: {
		query: {
			type: Object,
			required: true
		}
	},

	mixins: [RudderstackChatMixin],

	computed: {
		...mapState('search', ['dealership_id']),

		vehicleInfo() {
			const { isbysize, make } = this.query;

			if (isbysize) {
				const { width, ratio, diameter } = this.query;

				return `${make} ${width}/${ratio}/${diameter}`;
			}

			const { year, model, trim, option} = this.query;

			return `${year} ${make} ${model} ${trim} ${option}`;
		}
	},

	created() {
		this.$rudderstack.page(this.$route.meta.category, this.$route.meta.name, {
			...this.$route.query,
			'dealership_id': this.dealership_id
		});
	}
};
</script>
