import Vue from 'vue';
import Vuex from 'vuex';
// store modules
import appointmentStore from '@/store/appointment';
import carlineStore from '@/store/carline';
import dealerStore from '@/store/dealer.js';
import intialStore from '@/store/initial';
import mobileInstallationStore from '@/store/mobile-installation.js';
import productDetailStore from '@/store/product-details';
import productResultsStore from '@/store/product-results';
import productStore from '@/store/products';
import scheduleStore from '@/store/schedule';
import serviceOfferingsStore from '@/store/serviceOfferings';
import cartStore from '@/store/cart';
import contentStore from '@/store/content';
import modalStore from '@/store/modal';
import consumerInfoStore from '@/store/consumer-info';
import searchStore from '@/store/search';
import authviaStore from '@/store/authvia';

Vue.use(Vuex);

const store = new Vuex.Store({
	modules: {
		appointment: appointmentStore,
		carline: carlineStore,
		cart: cartStore,
		consumerInfo: consumerInfoStore,
		content: contentStore,
		dealer: dealerStore,
		initial: intialStore,
		modal: modalStore,
		productDetail: productDetailStore,
		productResults: productResultsStore,
		products: productStore,
		schedule: scheduleStore,
		serviceOfferings: serviceOfferingsStore,
		mobileInstallation: mobileInstallationStore,
		search: searchStore,
		authvia: authviaStore
	}
});

export default store;
