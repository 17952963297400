const store = {
	namespaced: true,

	state: {
		token: '',
		tokenValue: '',
		merchantClientID: '',
		paymentMethodSuccess: {},
		isCreatingTransaction: false
	},

	getters: {
		isAuthviaReady(state) {
			return state.tokenValue !== '' && state.merchantClientID !== '';
		},
		isPaymentSubmittedSuccessful(state) {
			return state.paymentMethodSuccess && Object.keys(state.paymentMethodSuccess).length > 0;
		}
	},

	mutations: {
		setAuthviaToken(state, payload) {
			state.token = payload;
		},
		setAuthviaTokenValue(state, payload) {
			state.tokenValue = payload;
		},
		setAuthviaMerchantClientID(state, payload) {
			state.merchantClientID = payload;
		},
		setPaymentMethodSuccess(state, payload) {
			state.paymentMethodSuccess = payload;
		},
		setIsCreatingTransaction(state, payload) {
			state.isCreatingTransaction = payload;
		}
	}
};

export default store;
