<!-- eslint-disable no-inline-comments -->
<!-- eslint-disable line-comment-position -->
<!-- eslint-disable complexity -->
<!-- eslint-disable complexity -->
<template>
    <div>
        <modal-out-of-stock
            :title="modalHeader"
            :message="modalMessage"
            :buttonText="buttonText"
            :showCloseButton="false"
            @onButtonClick="buttonAction"
        />

        <!-- Error Form Modal -->
        <dialog-modal
            :isOpen="showErrorFormModal"
            @close="buttonAction"
            :showCloseButton="false"
            :ariaLabel="modalHeader"
        >
            <h4 slot="header">{{ modalHeader }}</h4>
            <p v-html="modalMessage"></p>
            <button
                slot="footer"
                type="button"
                class="button button--primary"
                @click="buttonAction"
            >
                {{ buttonText }}
            </button>
        </dialog-modal>

        <!-- Appointment Error Modal - Time no longer available -->
        <dialog-modal
            :isOpen="isApptErrorModalOpen"
            :showCloseButton="false"
            :disableEscape="true"
            :ariaLabel="$t('message.notAvailable1')"
        >
            <span slot="header">
                <icon-calendar-warning width="32" height="32" class="appointment__error-icon" />
                <h3 class="appointment__error-message">{{ $t("message.notAvailable1") }}</h3>
            </span>

            <p>{{ $t("message.notAvailable2") }}</p>

            <button
                slot="footer"
                class="button button--primary"
                data-cy="scheduleButton"
                @click="$router.push({ path: '/app/schedule', query })"
            >
                {{ $t("message.updateAppointment") }}
            </button>
        </dialog-modal>

        <div class="row payment">
            <div class="col-12 header__page-heading" v-html="headline" data-cy="checkoutHeading"></div>
        </div>

        <div class="row payment__body">
            <div class="col-12 col-md-5">
                <div class="row">
                    <!-- Order Summary -->
                    <section class="col-12 order-summary">
                        <h2 class="order-summary__header">{{ $t("message.yourOrder") }}</h2>
                        <table v-if="tiresInCart.products" class="order-summary__table">
                            <tbody>
                                <tr
                                    v-for="(product, productIndex) in tiresInCart.products.filter(tire => tire.qty)"
                                    :key="'productIndex_' + productIndex"
                                    class="order-summary__row"
                                >
                                    <td class="order-summary__cell">
                                        <span class="order-summary--large-font"
                                        >{{ product.qty }}x</span>
                                    </td>
                                    <td class="order-summary__cell">
                                        <span class="order-summary--large-font">{{product.brand}}&nbsp;{{
                                            product.catalogEntryDescription
                                        }} </span>
                                        <div class="order-summary__tire-size">
                                            ({{ product.size }})
                                        </div>
                                    </td>
                                    <td v-if="isWheelPackage" class="order-summary__cell">
                                        {{ $n(packagePriceEach * product.qty, 'currency') }}
                                    </td>
                                    <td v-else class="order-summary__cell">
                                        {{ $n(product.pricing.installPrice, 'currency') }}
                                    </td>
                                </tr>
                                <tr
                                    v-for="(service, serviceIndex) in appointment.services"
                                    :key="'serviceIndex_' + serviceIndex"
                                    class="order-summary__row"
                                >
                                    <td class="order-summary__cell">
                                        <span class="order-summary--large-font">1</span>
                                    </td>
                                    <td class="order-summary__cell">
                                        <span class="order-summary--large-font">{{
                                            service.title
                                        }}</span>
                                        <div class="order-summary__tire-size" v-if="service.description">
                                            ({{ service.description }})
                                        </div>
                                    </td>
                                    <td class="order-summary__cell">{{ $n(service.price, 'currency') }}</td>
                                </tr>
                            </tbody>
                        </table>
                        <content-placeholder v-else :rows="tireRows" />
                        <template v-if="tiresInCart.products">
                            <h4 class="order-summary__includes-header">
                                {{ $t("message.includes") }}
                            </h4>
                            <ul class="order-summary__includes-list">
                                <li
                                    v-for="(include, includedIndex) in includes"
                                    :key="includedIndex"
                                    class="order-summary__includes-item"
                                >
                                    {{ include }}
                                </li>
                            </ul>
                        </template>
                    </section>

                    <!-- Totals -->
                    <section class="col-12 order-totals">
                        <table v-if="tiresInCart.products" class="order-totals__table">
                            <tbody>
                                <tr v-for="(promo, promoIndex) in promos" :key="promoIndex">
                                    <td class="order-summary__cell">{{ promo.title }}</td>
                                    <td></td>
                                </tr>
                                <tr v-if="promos.length">
                                    <td></td>
                                    <td class="order-summary__cell order-summary__discount">
                                        - {{ $n(tiresInCart.pricing.savings, 'currency') }}
                                    </td>
                                </tr>
                                <tr>
                                    <td colspan="2">
                                        <hr class="order-totals--border" />
                                    </td>
                                </tr>
                                <tr>
                                    <th class="order-totals__cell">
                                        {{ $t("message.subtotal") }}
                                    </th>
                                    <td class="order-totals__cell order-totals__cell--right">
                                        {{ $n(subTotal, 'currency') }}
                                    </td>
                                </tr>
                                <taxfield
                                    :product="tiresInCart"
                                    :packagePriceEach="packagePriceEach"
                                    :query="query"
                                    :service="appointment.services"
                                    :quantity="qtySelected"
                                    :isWheelPackage="isWheelPackage"
                                    @taxesDone="grandTotal"
                                ></taxfield>
                                <tr>
                                    <td colspan="2">
                                        <hr class="order-totals--border" />
                                    </td>
                                </tr>
                                <tr v-if="totalCost">
                                    <th class="order-totals__cell order-totals--total">
                                        {{ $t("message.total") }}
                                    </th>
                                    <td class=" order-totals__cell order-totals__cell--right order-totals--price">
                                        {{ $n(totalCost, 'currency') }}
                                    </td>
                                </tr>
                                <tr v-else>
                                    <content-placeholder :rows="priceRows" />
                                </tr>
                                <!-- mail-in rebates -->
                                <tr v-for="(rebate, rebateIndex) in rebates" :key="rebateIndex">
                                    <th class="order-totals__cell">{{ rebate.title }}</th>
                                </tr>
                                <tr v-if="rebates.length">
                                    <td></td>
                                    <td class="order-summary__cell order-summary__discount">
                                        - {{ $n(tiresInCart.pricing.futureSavings, 'currency') }}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <content-placeholder v-else :rows="priceRows" />
                    </section>
                </div>
            </div>

            <!-- Appointment -->
            <section
                v-if="!isCalendarShowing"
                class="col-12 col-md-6 mr-md-auto ml-md-auto"
                id="checkoutCalendar"
            >
                <div class="row appointment appointment--light-bg">
                    <h2 class="col-12 appointment__header">
                        {{ $t("message.appointment") }}
                    </h2>
                    <div class="col-12">
                        <i
                            v-if="!deliveryDateStillGood"
                            class="appointment__error-icon icon icon--exclamation-triangle"
                        ></i>
                        <table class="appointment__table">
                            <tbody>
                                <tr :class="{ 'appointment--error': !deliveryDateStillGood }">
                                    <td class="appointment__cell appointment__cell--left">
                                        {{ $t("message.when") }}
                                    </td>
                                    <td class="appointment__cell appointment__cell--right">
                                        <strong v-if="appointment.time.apptDateTime">
                                            {{ $d(new Date(appointment.time.apptDateTime), 'time') }},
                                            {{ $d(new Date(appointment.time.apptDateTime), 'long') }}
                                        </strong>
                                        <content-placeholder v-else :rows="tireRows" />
                                        <p
                                            v-if="!deliveryDateStillGood"
                                            class="appointment__error-message"
                                        >
                                            {{ $t("message.notAvailable1") }}
                                        </p>
                                        <button
                                            v-if="!deliveryDateStillGood"
                                            @click="toggleCalendar"
                                            class="appointment__button"
                                        >
                                            {{ $t("message.chooseAnother") }}
                                        </button>
                                    </td>
                                </tr>
                                <tr>
                                    <td colspan="3" class="appointment__error-padding"></td>
                                </tr>
                                <tr>
                                    <td
                                        class="appointment__cell appointment__cell--left"
                                        v-html="
                                            isMobileInstallation
                                                ? $t('message.onBehalfOf')
                                                : $t('message.where')
                                        "
                                    ></td>
                                    <td
                                        v-if="selectedDealer.dealershipname"
                                        class="appointment__cell appointment__cell--right"
                                    >
                                        <strong>{{ selectedDealer.dealershipname }}</strong>
                                        <br />
                                        {{ selectedDealer.address }}
                                        <br />
                                        {{ selectedDealer.city }}, {{ selectedDealer.state }}
                                        {{ selectedDealer.zip }}
                                        <br />
                                    </td>
                                    <td v-else class="appointment__cell appointment__cell--right">
                                        <content-placeholder :rows="tireRows" />
                                    </td>
                                </tr>
                                <tr v-if="!vehicle.isBySize">
                                    <td class="appointment__cell appointment__cell--left">
                                        {{ $t("message.for") }}
                                    </td>
                                    <td
                                        class="appointment__cell appointment__cell--right"
                                    >
                                        <strong>{{ vehicle.full }}</strong>
                                        <br />
                                        {{ $t("message.trim") }}: {{ vehicle.trim }}
                                        <br />
                                        {{ $t("message.option") }}: {{ vehicle.option }}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <section class="row">
                    <div class="col-12" v-html="policy"></div>
                </section>
                <!-- Financing -->
                <div
                    v-if="tiresInCart.products && showFinancing"
                    class="row"
                >
                    <financing-data
                        :financing-amounts="tiresInCart.financing"
                        :financing-disclaimer="financingDisclaimer"
                    >
                    </financing-data>
                </div>
            </section>
        </div>

        <div v-if="isWinter && !env.isCA" class="row winter">
            <div class="winter__content" v-html="winter"></div>
        </div>

        <div v-if="isOEMSpecialTransportation" class="row transport">
            <div class="transport__content" v-html="transport"></div>
        </div>

        <div v-if="isMobileInstallation" class="row mobile-install">
            <!-- TODO: JANKY update disclaimer to content -->
            <!-- <div v-if="mobileInstall" class="mobile-install__content" v-html="mobileInstall"></div> -->
            <div class="mobile-install__content">
                <strong>{{ $t("message.mobileInstallDisclaimerHeader") }}</strong>
                {{ $t("message.mobileInstallDisclaimer") }}
            </div>
        </div>

        <!-- Billing Info -->
        <!-- If CC is enabled, this will be the target of our submission form, so the CC information launches out of a new iframe -->
        <iframe title="Credit Card Form"
                id="submitPaymentPost"
                name="submitPaymentPost"
                class="checkout--iframe-wrapper"
        ></iframe>
        <CheckoutForm
            :isRefNumAvail="isRefNumAvail"
            :hasCreditCardCheckout="hasCreditCardCheckout"
            :taxesDone="cybersourceFinished"
            :errorDuringSubmission="errorDuringSubmission"
            :appointmentUpdated="newAppointmentChosen"
            :submitForm="orderButtonClicked"
            :appointment="appointment"
            :newAppointmentChosen="newAppointmentChosen"
            :isWheelPackage="isWheelPackage"
            @validForm="checkInventory"
            :showFinancing="showFinancing"
            :financingDisclaimer="financingDisclaimer"
            :financePartner="selectedDealer.financePartner"
            ref="checkoutform"
        />

        <div v-if="showFinancing">
            <p
                class="finance-disclaimer"
                v-html="estimatedPaymentDisclaimer"
            ></p>
        </div>

        <!-- Ad -->
        <!-- <div class="row" v-html="ad"></div> -->
        <!-- REPLACE CONTENT AD -->
        <div class="container">
            <div class="block block__contentBlock " style="">
                <div class="col ">
                    <p style="font-size:10px;"><sup>*</sup>{{ taxDisclaimer }}</p>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
import axios from 'axios';
import CheckoutForm from '@/components/CheckoutForm.vue';
import ContentPlaceholder from 'vue-content-placeholder';
import AppointmentNavigation from '@/components/AppointmentNavigation.vue';
import ModalOutOfStock from '@/components/ModalOutOfStock.vue';
import packagePrices from '../store/data/wheel-package-prices';
import Taxfield from '@/components/Taxfield.vue';
import { saveStateToSession } from '@/utils/receipt-session';
import { last, isEmpty, pluck } from 'underscore';
import { format, differenceInHours, addHours, addDays, isAfter } from 'date-fns';
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
import {
	orderSubmitAlert,
	cartImpression,
	paymentPageError
} from '@/utils/setDataLayer';
import serialize from 'form-serialize';
import FinancingData from '@/components/FinancingData.vue';
import DialogModal from './DialogModal.vue';
import DealerHeader from '@/components/DealerHeader.vue';
import IconCalendarWarning from '@/components/IconCalendarWarning';
import fetchTimeSlots from '@/utils/fetchTimeSlots';
import * as currency from '@/utils/currency';

// eslint-disable-next-line camelcase
window.handleSubmitResponse = (status, { payment_token = '', req_card_type = '' }) => {
	window.paymentInstance.finalPlacement(status, payment_token, req_card_type);
};

export default {
	props: {
		query: {
			required: true,
			type: Object
		},
		hasCreditCardCheckout: {
			required: true,
			type: Boolean
		}
	},

	components: {
		ContentPlaceholder,
		ModalOutOfStock,
		Taxfield,
		CheckoutForm,
		FinancingData,
		DialogModal,
		AppointmentNavigation,
		DealerHeader,
		IconCalendarWarning
	},

	data() {
		return {
			form: 'checkout_form',
			orderButtonClicked: false,
			errorDuringSubmission: false,
			isRefNumAvail: false,
			isApptErrorModalOpen: false,
			isCalendarShowing: false,
			consumerInfoForAppt: {},
			deliveryDateStillGood: true,
			deliveryBufferHours: 0,
			cybersourceFinished: false,
			totalCost: '',
			appointmentProvider: '',
			tireRows: [
				{
					height: '40px',
					boxes: [[0, '100%']]
				}
			],
			priceRows: [
				{
					height: '60px',
					boxes: [[0, '100%']]
				}
			],
			modalHeader: '',
			modalMessage: '',
			buttonText: '',
			isOutOfStock: false,
			isErrorFormModalOpen: false,
			type: '',
			appointment: {
				type: {
					name: '',
					id: 0
				},
				date: '',
				time: {
					offset: 0,
					apptDateTime: '',
					blockStartTime: '',
					appointmentDate: '',
					appointmentTime: '',
					blockDurationMinutes: 0
				},
				services: [],
				provider: '',
				mobileInstallZip: '',
				mobileInstallationID: 13,
				comments: ''
			},
			newAppointmentChosen: false
		};
	},

	methods: {
		...mapActions('products', ['getInventory', 'setProducts']),
		...mapActions('appointment', ['createAppointment', 'getAppointment', 'setAppointment']),
		...mapActions('dealer', ['getDealer', 'select']),
		...mapActions('cart/order', [
			'updateProductPaymentObject',
			'signatureRequest',
			'consumerLeadInitial',
			'consumerLead',
			'consumerOrderQueue',
			'setPromotionsForOrder'
		]),
		...mapMutations('cart/order', ['orderPayment']),
		...mapMutations('authvia', ['setIsCreatingTransaction']),
		// eslint-disable-next-line complexity
		logISError(message, ErrorObj = null) {

			let skus = pluck(this.tiresToShow, 'sku');

			axios.post('/service/errorlog', {
				data: {
					message: message,
					error: ErrorObj,
					isRefNumAvail: this.isRefNumAvail,
					// dealerCode: this.selectedDealer.dealerCode, // was told this may be security concern
					dealerID: this.selectedDealer.dealershipid,
					qty: this.qtySelected.join(','),
					sku: skus.join(','),
					oem: this.selectedDealer.program,
					query: this.query
				}
			});
		},
		// eslint-disable-next-line complexity
		logError(errorLocation) {

			let skus = pluck(this.tiresToShow, 'sku');

			axios.get('/service/errorlog', {
				params: {
					message: 'error on checkout page',
					loc: errorLocation,
					error: this.err,
					isRefNumAvail: this.isRefNumAvail,
					email: this.details.consumer.email,
					qty: this.qtySelected.join(','),
					sku: skus.join(','),
					query: this.query

				}
			});
		},
		previousPage() {
			let r = this.$router.resolve({
				path: this.isMobileInstallation ? '/app/schedule' : '/app/services',
				query: this.query
			});

			window.location.assign(r.href);
		},
		async submitOrder() { // eslint-disable-line complexity
			let { error: createAppointmentError } = await Promise.all([
				// Creates ESAPI appointment
				this.createAppointment({
					consumer: this.consumerInfoForAppt,
					vehicle: this.query,
					products: this.details.products,
					isSearchBySize: Boolean(this.query.isbysize),
					blockDurationMinutes: this.appointment.time.blockDurationMinutes,
					blockStartTime: this.appointment.time.blockStartTime,
					requestedDate: this.appointment.time.appointmentDate
				}),
				// Action - calls cart/getCart, commits cart/order/setOrder
				this.setProducts()
			]);

			if (createAppointmentError) {
				this.logError('signature request error 2');
				this.setErrorDuringSubmission();
				paymentPageError(this.appointmentProvider);

				throw new Error(createAppointmentError);
			}
			// Remove free-text features from all products.
			// In the future, we should move Joi validation here where it can also do this for us.

			for (const product of this.details.products) {
				product.features = '';
				product.overview = '';

				// In demo and dev, circumvent the "block all ;ll" rule by mangling descriptions
				if (this.env.isDev || this.env.isDemo || this.env.isQa || this.env.isStaging) {
					for (const promotion of this.details.promotions) {
						promotion.title = promotion.title.replace(/;ll/g, '; will');
						promotion.description = promotion.description.replace(/;ll/g, '; will');
					}
				}
			}

			// if (this.query.isbysize) {
			// 	delete this.details.vehicle;
			// }

			this.details.amenities = this.appointment.type;
			this.details.services = this.appointment.services;
			this.details.query = this.query;

			if (!this.selectedDealer.authviaEnabled) {

				let leadInitialError = await this.consumerLeadInitial(this.details); // eslint-disable-line

				if (leadInitialError) {
					this.logError('signature request error');
					this.setErrorDuringSubmission();

					throw new Error(leadInitialError);
				}
			}

			if (this.hasCreditCardCheckout) {
				const form = document.getElementById('checkout_form');
				let formData = serialize(form, { hash: true });
				let trimPayload = {};

				// Doing this to make IE happy because the form value
				// Doesn't get filled out fast enough before serializing
				formData['reference_number'] = this.details.leadID;

				// Set up signature request with just the fields needed to make a signature.
				let signedFieldNames = formData.signed_field_names.split(','); // eslint-disable-line camelcase

				for (const field of signedFieldNames) {
					trimPayload[field] = formData[field];
				}

				return this.signatureRequest(trimPayload)
					.then(() => {
						// Lot Ball, forgive us this sin we must commit as our old foe IE
						// Hath decided it won't wait for Vue to fill in the appropriate
						// Form element before a submission. We know this to be against your law
						// But hope you will grant us absolution of this atrocity against You
						// And all that is good. Our causes are just.
						this.setIsCreatingTransaction(false);

						return setTimeout(() => form.submit(), 500);
					});
			}

			return this.finalPlacement();
		},
		formatDateString(date) {
			const year = date.getFullYear();
			const month = String(date.getMonth() + 1).padStart(2, '0');
			const day = String(date.getDate()).padStart(2, '0');


			// example: yyyy-mm-dd
			return `${year}-${month}-${day}`;

		},
		// eslint-disable-next-line complexity
		async appointmentSlotAvailable() {
			try {
				let startDate = addHours(new Date(`${this.appointment.time.appointmentDate}T${this.appointment.time.appointmentTime}:00.000Z`), this.appointment.time.offset * -1);
				let { make, year, model, qty } = this.query;

				const timeSlots = await fetchTimeSlots({
					startDate: startDate && isAfter(startDate, this.buffedDeliveryDate) ? startDate : this.buffedDeliveryDate,
					make,
					transportationOption: 10,
					year,
					model,
					qty,
					deliveryDate: this.buffedDeliveryDate
				});

				if (typeof timeSlots === 'object') {
					if (typeof timeSlots.openDates !== 'undefined' && timeSlots.openDates.length > 0) {
						let morningAppt = this.appointment.time.blockStartTime.includes('AM');
						let appointmentTime = this.appointment.time.blockStartTime.replace(/^0+/, '').replace(':00 PM', '').replace(':00 AM', '');


						if (typeof timeSlots.openDates[0].date !== 'undefined' && this.formatDateString(timeSlots.openDates[0].date) === this.appointment.time.appointmentDate ) {
							if (typeof timeSlots.openDates[0].timeslots !== 'undefined' && ((morningAppt) ? timeSlots.openDates[0].timeslots.am.length > 0 : timeSlots.openDates[0].timeslots.pm.length > 0)) {
								let apptTimeslots = timeSlots.openDates[0].timeslots;

								if (morningAppt) {
									apptTimeslots = apptTimeslots.am;
								} else {
									apptTimeslots = apptTimeslots.pm;
								}
								// instead of 12:00am alter to 0:00am
								// eslint-disable-next-line complexity
								let slotIsAvailable = apptTimeslots.find(slot => (slot.isAvail && (slot.time === ((appointmentTime.slice(0, 2) === '12' && morningAppt) ? `0${ appointmentTime.substring(2)}` : appointmentTime) + ((morningAppt) ? 'am' : 'pm') )));

								// console log matched appointment for prod debugging purposes
								console.log('slotIsAvailable: ', {
									date: this.formatDateString(timeSlots.openDates[0].date),
									slotSearchTime: appointmentTime + ((morningAppt) ? 'am' : 'pm'),
									timeslot: slotIsAvailable
								});


								return (typeof slotIsAvailable !== 'undefined');
							}
						}


					}


					return false;
				}


			} catch (error) {

				// gracefully handle errors // if we have to more than 3 statements to match swap to a switch statement
				if (error.message === 'Error fetching timeslots') {
					console.log('got an error with timeslots - please try again later');
					this.isTimeSlotError = true;
					this.TimeSlotErrorMessage = 'Error retrieving timeslots - please try again later.';
				} else if (error.message === 'no time slots available this month') {
					console.log('no time slots available this month - please try another month');
				} else {
					console.log('got an error with timeslots - please try again later', error);

					return false;
				}

				return false;

			}

			return false;

		},
		async checkDeliveryDate() {
			const dealerTime = addHours(new Date(`${this.appointment.time.appointmentDate}T${this.appointment.time.appointmentTime}:00.000Z`), this.appointment.time.offset * -1);

			// Selected time dealer and delivery date are in the ${this.appointment.time.appointmentDate} dealer's timezone.
			if (differenceInHours(dealerTime, new Date(this.deliveryDate)) >= 24) {
				let iSslotAvailable = await this.appointmentSlotAvailable();

				if (iSslotAvailable) {
					this.deliveryDateStillGood = true;
					this.isApptErrorModalOpen = false;

					return this.submitOrder();
				}
			}

			// The calendar needs to be shown and you can't move forward
			this.isApptErrorModalOpen = true;
			this.deliveryDateStillGood = false;
			this.newAppointmentChosen = false;
			document.getElementById('checkoutCalendar').scrollIntoView(true);

			// Sets data layer
			return orderSubmitAlert({
				alertCategory: 'Appointment',
				alertMessage: 'Appointment time is no longer valid'
			});
		},
		toggleCalendar() {
			this.$refs.flipperContainer.classList.toggle('flip-hidden');
			this.$refs.flipperContainer.classList.toggle('flip');
			this.isCalendarShowing = !this.isCalendarShowing;
			this.dateCurrentState = true;
		},
		goBack() {
			let r = this.$router.resolve({
				path: this.isMobileInstallation ? '/app/schedule' : '/app/services',
				query: this.query
			});

			window.location.assign(r.href);
		},
		checkInventory(consumer) {
			this.$refs.checkoutform.ccDeclineHide();
			this.consumerInfoForAppt = consumer;

			try {
				this.getInventory({
					products: this.tiresInCart.products,
					qty: isEmpty(this.qtySelected) ? this.defaultQty : this.qtySelected,
					dealer: this.selectedDealer
				}).then((prodMap) => {
					if (this.oos || !prodMap) {
						this.setProductName(
							last(this.tiresInCart.products).catalogEntryDescription
						);

						// Sets data layer
						return orderSubmitAlert({
							alertCategory: 'No product',
							alertMessage: 'Product is out of stock'
						});
					}

					return this.checkDeliveryDate();
				});

			} catch (error) {
				this.logISError('item sourcing request error on checkout page', error.message);
				// still throw the error?
				throw (error);
			}
		},
		async finalPlacement(status, subscriptionID, type) { // eslint-disable-line
			// See Troubleshooting.md when moving parts of this function
			if (status === 'failure') {
				this.$refs.checkoutform.ccDecline();

				return true;
			}
			if (subscriptionID && type) {
				// Sets store.cart.order.details.payment
				this.orderPayment({
					subscriptionID,
					type
				});
			}

			let consumerLeadError = await this.consumerLead({
				...this.details,
				dealer: this.selectedDealer
			});

			if (consumerLeadError) {
				if (
					consumerLeadError.consumerLeadError
					&& consumerLeadError.response.data
					&& consumerLeadError.response.data.failureCode === 'email'
				) {
					this.isRefNumAvail = true;
					this.setErrorReferenceNumberAvailable();
				}
				this.isRefNumAvail = true;
				this.logError('order placement or save state to session error');
				this.setErrorReferenceNumberAvailable();
			}

			let localePricing = {
				frontSavings: this.$n(this.details.products[0].pricing.savings, 'currency'),
				grandTotalAmount: this.$n(this.details.payment.grandTotalAmount, 'currency'),
				supplementalChargesAmount: this.$n(this.details.payment.supplementalChargesAmount, 'currency'),
				subTotalAmount: this.$n(this.details.payment.subTotalAmount, 'currency'),
				defaultAmount: this.$n(0.00, 'currency'),
				services: this.details.services.map(obj => {
					obj.price = this.$n(Number(obj.price).toFixed(2), 'currency');

					return obj;
				}),
				tires: this.details.products.map(obj => {
					obj.pricing.modInstallPriceWithCurrency = this.$n(Number(obj.pricing.installPrice / 4).toFixed(2), 'currency');
					obj.pricing.totalInstallPriceWithCurrency = this.$n(Number((obj.pricing.installPrice / 4) * obj.qty).toFixed(2), 'currency');
					obj.pricing.unitInstallPriceWithCurrency = this.$n(Number(obj.pricing.unitInstallPrice).toFixed(2), 'currency');
					obj.pricing.installPriceWithCurrency = this.$n(Number(obj.pricing.installPrice).toFixed(2), 'currency');

					return obj;
				})
			};

			// We need to get the total savings for the email
			// We are guaranteed to have a front product
			let totalSavings = this.details.products[0].pricing.savings;

			if (this.details.products.length > 1 ) {
				localePricing.rearSavings = this.$n(this.details.products[1].pricing.savings, 'currency');
				totalSavings += this.details.products[1].pricing.savings;
			}

			localePricing.savings = this.$n(totalSavings, 'currency');

			// Only submit the order if everything else went well.
			// add data needed for emails
			let orderDetails = Object.assign({}, this.details,
				{
					isBySize: Boolean(this.query.isbysize),
					localePricing,
					dealer: this.selectedDealer
				});

			// submit order request
			try {
				let res = await axios({
					url: '/service/order',
					method: 'POST',
					data: orderDetails
				});

				if (res.status !== 200 && res.status !== 201) {
					this.logError(`Error with order submission - non 200: ${ JSON.stringify({
						status: res.status,
						data: res.data,
						leadID: this.details.leadID,
						dealershipid: this.selectedDealer.dealershipid
					})}`);
				} else if (
					(typeof res.data.status !== 'undefined' && res.data.status === 'Everyting is fine...')
					|| typeof res.data.consumerorderqueuesubmissionresponse !== 'undefined'
				) {
					if (
						(typeof res.data.status !== 'undefined' && res.data.status === 'Everyting is fine...')
						|| typeof res.data.consumerorderqueuesubmissionresponse.ordernumber === 'undefined'
						|| res.data.consumerorderqueuesubmissionresponse.ordernumber === ''
					) {
						if (process.env.NODE_ENV !== 'production') {
							// eslint-disable-next-line no-alert
							alert('URGENT NOTICE: MISSING ORDER NUMBER!!!');
							this.logError(`Error with order submission - NO JDE ORDER NUMBER: ${ JSON.stringify({
								status: res.status,
								data: res.data,
								leadID: this.details.leadID,
								dealershipid: this.selectedDealer.dealershipid
							})}`);
						}
					} else {
						console.log('ORDER NUMBER FOUND: ', res.data.consumerorderqueuesubmissionresponse.ordernumber);
					}
				} else {
					this.logError(`Error with order submission: ${ JSON.stringify({status: res.status,
						data: res.data,
						leadID: this.details.leadID,
						dealershipid: this.selectedDealer.dealershipid
					})}`);
				}

			} catch (err) {
				console.error(`Caught Error with order submission`, err);

				this.logError(`Caught Error with order submission ${ JSON.stringify({
					err: err.message,
					leadID: this.details.leadID,
					dealershipid: this.selectedDealer.dealershipid
				})})}`);
			}

			// // todo: evaluvate this code block as it cant function as intended based on my findings
			// if (consumerOrderQueError) {
			// 	if (consumerOrderQueError.response && consumerOrderQueError.response.data &&
			// consumerOrderQueError.response.data.failureCode === 'email') {
			// 		this.isRefNumAvail = true;
			// 		this.setErrorReferenceNumberAvailable();
			// 	}
			// 	this.isRefNumAvail = true;
			// 	this.logError('order placement or save state to session error');
			// 	this.setErrorReferenceNumberAvailable();
			// }

			// commit order details to store
			await this.consumerOrderQueue(orderDetails);

			let r = this.$router.resolve({
				path: '/app/receipt',
				query: this.query
			});

			await saveStateToSession(this.$store.state, this.selectedDealer, this.isWheelPackage, this.packagePrice).then(() => {
				return window.location.assign(r.href);
			});

		},
		setProductInCart(pSet) {
			const pData = pSet.products;
			const rebates = this.rebates;
			const applicablePromotions = this.promos;

			let promos = [];

			if (!isEmpty(applicablePromotions)) {
				applicablePromotions.forEach(promo => promos.push(promo));
			}
			if (!isEmpty(rebates)) {
				rebates.forEach(promo => promos.push(promo));
			}
			this.setPromotionsForOrder(promos);
			this.setProducts(pData);
		},
		// eslint-disable-next-line complexity
		grandTotal(payment) {
			if (!currency.equalsCurrency(this.subTotal, payment.subTotalAmount) || payment.grandTotalAmount < this.subTotal ) {
				this.logError(`Order Total Mismatch (real subtotal: ${this.subTotal}) (subTotal: ${payment.subTotalAmount}) (grandTotal: ${payment.grandTotalAmount})`);
				payment.subTotalAmount = this.subTotal;
				payment.grandTotalAmount = currency.addCurrency(this.subTotal, payment.supplementalChargesAmount).toFixed(2);
				// eslint-disable-next-line no-undefined
				if (typeof payment.subscriptionID === undefined ) {
					payment.subscriptionID = '';
				}
				// eslint-disable-next-line no-undefined
				if (typeof payment.type === undefined) {
					payment.type = '';
				}
				this.updateProductPaymentObject(payment);
			}

			this.totalCost = payment.grandTotalAmount;
			this.cybersourceFinished = true;
			this.setProductInCart(this.tiresInCart);
			this.$emit('taxesDone', payment);
		},
		setErrorDuringSubmission() {
			this.errorDuringSubmission = true;
			this.isRefNumAvail = false;
			this.type = 'error';
			this.modalHeader = this.$t('message.checkoutErrorHeader');
			this.modalMessage = this.$t('message.checkoutErrorMessage', { phone: this.phoneNumber });
			this.buttonText = this.$t('message.checkoutErrorButtonText');
			this.isErrorFormModalOpen = true;
		},
		setErrorReferenceNumberAvailable() {
			this.isRefNumAvail = true;
			this.errorDuringSubmission = false;
			this.type = 'alert';
			this.modalHeader = this.$t('message.checkoutErrorRecieptHeader');
			this.modalMessage = this.$t('message.checkoutErrorRecieptMessage', {
				refNum: this.details.leadID,
				phone: this.phoneNumber});
			this.buttonText = this.$t('message.continue');
			this.isErrorFormModalOpen = true;
		},
		setOutOfStock() {
			if (this.oos) {
				this.modalHeader = this.$t('message.oosHeader', {tire: this.selectedProdName });
				this.modalMessage = this.$t('message.oosMsg', {phone: this.phoneNumber});
				this.buttonText = this.$t('message.selectDifferentTire');
				this.type = 'error';
			}
		},
		setNewAppointmentDate(newAppointmentDate) {
			this.newAppointment.date = newAppointmentDate;
			this.appointment.date = newAppointmentDate;
			this.dateHeadingText = format(newAppointmentDate, 'PPP');
			this.dateCurrentState = false;
			this.timeCurrentState = true;
		},
		setNewAppointmentTime(newAppointmentTime) {
			this.newAppointment.time = newAppointmentTime;
			this.timeHeadingText = format(newAppointmentTime.apptDateTime, 'K:mmaaa');
			this.appointment.time = newAppointmentTime;
			this.timeCurrentState = true;
		},
		buttonAction() {
			if (this.errorDuringSubmission) {
				this.$router.push({
					path: '/app/schedule',
					query: this.query
				});
			}
			if (this.isRefNumAvail) {
				axios.delete('/service/session').then(
					this.$router.push('/')
				);
			}
			if (this.oos) {
				this.$router.push({
					path: '/app/product',
					query: this.query
				});
			}
		},
		updateTimeSlots({ dates, interval, provider }) {
			this.timeSlotsForNewAppointment = dates;
			this.blockDurationMinutes = interval;
			this.appointment.provider = provider;
		}
	},

	computed: {
		...mapGetters('cart/order', [
			'isWinter',
			'isOEMSpecialTransportation'
		]),
		...mapGetters('appointment', [
			'isMobileInstallation'
		]),
		...mapState('cart/order', ['details']),
		...mapState('appointment', ['schedule']),
		...mapState('products', [
			'deliveryDate',
			'defaultQty',
			'qtySelected',
			'selectedProdName',
			'formName'
		]),
		...mapState('content/payment', [
			'policy',
			'headline',
			'winter',
			'transport'
		]),
		...mapState('initial', ['phoneNumber', 'env', 'message']),
		...mapState('dealer', ['selected']),
		...mapState('dealer', {
			selectedDealer: 'selected'
		}),
		...mapState('cart', ['servicesInCart', 'tiresInCart']),
		...mapState('modal', ['oos']),
		includes() {
			if (this.isWheelPackage) {
				const wpki = [
					this.$t('message.wheel'),
					this.$t('message.tire'),
					this.$t('message.centerCap'),
					this.$t('message.tpms')
				];

				return wpki;

			}
			const aioIncludes = [
				this.$t('message.tires'),
				this.$t('message.mountingBalancingInstallation'),
				this.$t('message.disposalFees'),
				this.$t('message.shopSupplies')
			];

			if (
				this.tiresInCart.products &&
                last(this.tiresInCart.products).showRoadHazard
			) {
				aioIncludes.push(this.$t('message.roadHazard'));
			}

			return aioIncludes;

		},
		isWheelPackage() {
			return this.tiresInCart.products ? this.tiresInCart.products[0].programCategory.toUpperCase() === 'WPK' : false;
		},
		packagePrice() {
			return packagePrices[this.tiresInCart.products[0].sku] || null;
		},
		packagePriceEach() {
			return this.packagePrice ? this.packagePrice.installPrice / 4 : null;
		},
		financingDisclaimer() {
			if (!isEmpty(this.selectedDealer.financePartner && this.selectedDealer.financePartner.code && this.selectedDealer.financePartner.description)) {
				return this.selectedDealer.financePartner;
			}

			return {};
		},
		estimatedPaymentDisclaimer() {
			if (!this.selectedDealer.financePartner) {
				return null;
			}

			return this.selectedDealer.financePartner.estimatedPaymentDisclaimer;
		},
		financing() {
			if (!isEmpty(this.tiresInCart.financing)) {
				return this.tiresInCart.financing;
			}

			return [];
		},
		promos() {
			if (!isEmpty(this.tiresInCart.promotions)) {
				return this.tiresInCart.promotions.filter(
					(p) => p.applied && p.type !== 'rebate'
				);
			}

			return [];
		},
		rebates() {
			if (!isEmpty(this.tiresInCart.promotions)) {
				return this.tiresInCart.promotions.filter(
					(p) => p.applied && p.type === 'rebate'
				);
			}

			return [];
		},
		subTotal() {
			const subtotal = this.appointment.services.reduce(
				(accu, s) => currency.addCurrency(accu, Number(s.price)),
				Number(this.isWheelPackage ? currency.multiplyCurrency(this.packagePriceEach, this.query.qty) : this.tiresInCart.pricing.installPriceAfterSavings)
			);

			return (subtotal).toFixed(2);
		},
		vehicle() {
			const qs = this.query;

			return {
				full: `${qs.year} ${qs.make} ${qs.model}`,
				make: qs.make,
				model: qs.model,
				year: qs.year,
				trim: qs.trim,
				option: qs.option,
				isBySize: qs.isbysize
			};
		},
		taxDisclaimer() {
			if (this.isMobileInstallation) {
				return this.$t('message.taxDisclaimerMobileInstall');
			}

			return this.$t('message.taxDisclaimer');
		},
		showErrorFormModal() {
			return (this.isErrorFormModalOpen && !this.oos);
		},
		showFinancing() {
			return this.selectedDealer.hasFinancingOTS && this.selectedDealer.financePartnerCode && !this.isMobileInstallation && !this.isWheelPackage;
		},
		buffedDeliveryDate() {// eslint-disable-line complexity
			this.deliveryBufferHours = this.selected.otsSchedulingBufferHours;
			// first, if we're here with an invalid date, or a blank date, bandaid with a week from today
			let deliveryDateBase = addDays(new Date(), 7);
			// parse the given delivery date as gmt so we can apples-to-apples with schedule hours
			let valiDate = new Date(`${this.deliveryDate}Z`);

			if (valiDate && Object.prototype.toString.call(valiDate) === '[object Date]' && !isNaN(valiDate)) {
				deliveryDateBase = valiDate;
				// blank string delvery dates are not log-worthy.
				// Initial page load has a blank delivery date
				// and problems with the delivery date fetch are logged elsewhere, leaving DD blank
				// only log if it comes in weird, which might be a bad actor, or an actual problem
			} else if (this.deliveryDate !== '') {
				console.log('invalid date format on checkout page: ', this.deliveryDate);
				axios.get('/service/errorlog', {
					params: {
						message: 'invalid date format on checkout page',
						dealerUid: this.query.uid,
						deliveryDate: this.deliveryDate,
						userAgent: navigator.userAgent
					}
				});
			}

			// the delivery date day/hours are in the dealer's timezone. Interpret as UTC so we can add hours.
			// before displaying in the schedule, we'll be able to add offset hours to get back to the correct time.

			//  minDeliveryBufferHours
			let buff = 24;

			let dayOfWeek = new Date();
			// Mobile installation has no delivery buffer beyond our minimum


			if (!this.appointment.type.id || this.appointment.type.id !== 13) {
				// add dealer's hours
				// if they refresh on this page, we re-fetch dealer info,
				// but just in case, let's not lock the scheduling page with an undefined buffer
				buff += this.deliveryBufferHours ? this.deliveryBufferHours : 0;
			} else if (this.appointment.type.id && this.appointment.type.id === 13 && dayOfWeek.getDay() === 5) {
				// Add 24hr buffer only on MI order for fridays ONLY
				buff += 24;
			}

			return addHours(deliveryDateBase, buff);
		}
	},

	watch: {
		'appointment.apptDateTime'() {
			if (!this.deliveryDateStillGood) {
				this.checkDeliveryDate();
			}
		},
		tiresInCart(tires) {
			if (!isEmpty(tires)) {
				// Sets dataLayer
				cartImpression({
					products: tires.products,
					qty: isEmpty(this.qtySelected) ? this.defaultQty : this.qtySelected,
					localBest: this.query.localBest
				});
			}
		}
	},

	updated() {
		this.setOutOfStock();
	},

	async created() {
		this.appointment = await this.getAppointment();
		this.appointmentDate = new Date(this.appointment.date);
		this.appointmentProvider = this.query.prov;

		if (this.hasCreditCardCheckout) {
			// exposed method to frontend in safer way
			window.paymentInstance = {finalPlacement: this.finalPlacement};
		}
	}
};
</script>
