<template>
    <div :class="['tile__flex']">

        <!-- Wheel Package Pricing/QTA/CTA -->
        <div v-if="!isStaggered && isWheelPackage" ref="pricingWrapper">
            <!-- Pricing -->
            <div class="row tile__pricing-wrapper" @click="showPriceBreakdown = !showPriceBreakdown">

                <!-- INSTALL PRICE COLUMN -->
                <div class="col-12">
                    <p :class="[
                        'tile__install-price-desc',
                        { 'tile__install-price-desc--active': showPriceBreakdown },
                    ]">
                        <button :aria-label="suggestedRetailPrice" :class="[
                            'tile__install-arrow',
                            { 'tile__install-arrow--active': showPriceBreakdown },
                        ]" type="button">
                            <span class="accessibility__label">
                                {{ suggestedRetailPrice }}
                            </span>
                        </button>
                        {{ suggestedRetailPrice }}
                    </p>
                    <p
                        :class="[
                            'tile__price',
                            'tile__price--install',
                            { 'tile__price--active': showPriceBreakdown },
                        ]"
                    >
                        {{ $n(packagePriceEach , 'currency') }}
                    </p>
                </div>

                <div v-if="showPriceBreakdown" class="col-12 tile__install-cms" v-html="packageBreakdown"></div>
            </div>

            <div class="row tile__pricing-wrapper">
                <!-- Quantity -->
                <form class="tile__qty-button-wrapper" @submit.prevent="checkInventory">
                    <div class="col-sm-6 tile__quantity-input-wrapper">
                        <label>
                            <span class="accessibility__label">{{
                                $t("message.accessibilityTireQty")
                            }}</span>
                            <input class="tile__quantity-input" disabled type="number" v-model.number="locQty.front" />
                        </label>
                    </div>

                    <!-- CTA Button -->
                    <div class="col-sm-6">
                        <statefulBtn :isLoading="isLoading" :optClass="statefulBtnClass" data-cy="productTileChooseButton">
                        {{$t("message.choose") }}</statefulBtn>
                    </div>
                </form>

                <!-- Set of 4 Price -->
                <div class="col-12 tile__price-set-of-4">
                    <p class="tile__set-of-4-msg">
                        {{ $t(tireMessage) }}
                        <span class="tile__no-wrap">
                            <strong>{{
                                $n((packagePriceEach * locQty.front) , 'currency')
                            }}</strong>
                        </span>
                    </p>
                    <p>{{ $t("message.taxInstallNotIncluded") }}</p>
                </div>
            </div>
        </div>

        <div class="details" v-html="details"></div>
    </div>
</template>

<script>
import { extend, isEmpty } from 'underscore';
import { mapActions, mapMutations, mapState, mapGetters } from 'vuex';
import { noDataEvent, productSkuEvent, productTileChoose } from '@/utils/setDataLayer';
import statefulBtn from '@/components/StatefulButton.vue';
import FinancingData from '@/components/FinancingData.vue';
import packagePrices from '../store/data/wheel-package-prices';
import { RudderstackProductsMixin } from '@/rudderstack/track/mixins/products';

export default {
	name: 'TileShopBlockPackage',

	props: {
		query: {
			type: Object,
			required: true
		},
		tilePosition: {
			required: false,
			type: Number,
			default: 0
		},
		productSet: {
			required: true
		},
		isOnDetailPage: {
			type: Boolean,
			required: false,
			default: false
		},
		isWheelPackage: {
			type: Boolean,
			required: false,
			default: false
		},
		statefulBtnClass: {
			type: String,
			default: 'button button--primary button--full tile--button'
		}
	},

	mixins: [RudderstackProductsMixin],

	components: {
		statefulBtn,
		FinancingData
	},

	data() {
		return {
			isLoading: false,
			// isPromoOpen: false,
			openPromoIndex: -1,
			locQty: {},
			showPriceBreakdown: false
		};
	},

	watch: {
		qtySelected([front, rear]) {
			this.locQty = {
				front,
				rear
			};
		}
	},

	methods: {
		...mapMutations('products', ['setProductName', 'updateQty']),
		...mapActions('cart', ['addPackageToCart']),
		...mapActions('products', ['getWheelPackageInventory']),
		isEmpty,
        qtyChange() { //eslint-disable-line
			// update the quantity so all tire prices update together
			let newQty = [this.locQty.front];

			if (this.locQty.rear) newQty.push(this.locQty.rear);

			if (this.isStaggered) {
				if (!this.locQty.front) {
					newQty = !this.locQty.rear || this.locQty.rear < 1 ? [1, 0] : [0, this.locQty.rear];
					this.locQty.front = newQty[0];
				}
				if (!this.locQty.rear) {
					newQty = !this.locQty.front || this.locQty.front < 1 ? [0, 1] : [this.locQty.front, 0];
					this.locQty.rear = newQty[1];
				}
			} else if (typeof this.locQty.front === 'string') {
				newQty = [1];
				this.locQty.front = newQty[0];
			}
			this.updateQty(newQty);
		},
		// needs to be async so that we have product info loaded + sourcing is done before going to schedule
        async checkInventory() { // eslint-disable-line

			this.isLoading = true;
			const skusAndQty = await this.getWheelPackageInventory({
				products: [this.productSet],
				qty: this.qtySelected.length ? this.qtySelected : this.defaultQty,
				dealer: this.selected
			});

			if (!skusAndQty && !this.oos) {
				this.isLoading = false;
				throw new Error('No inventory');
			}
			this.isLoading = false;
			this.confirmTirePurchase([this.productSet], skusAndQty);
		},
		async confirmTirePurchase(products, skusAndQty) {
			if (!this.oos && skusAndQty) {
				skusAndQty[0].promos = this.promos;
				await this.addPackageToCart(skusAndQty);
				this.goToSchedule(skusAndQty);
			} else {
				this.setProductName(products[0].catalogEntryDescription);
			}
		},
        goToSchedule() {//eslint-disable-line
			// Sets data layer
			if (this.isOnDetailPage) {
				// NOTE: Details page currently not in use for WPKs - This is for potential future use
				productSkuEvent('productDetailsPageEngageChoose', this.productSet);
				productSkuEvent('productDetailsPageEngageChooseWpk', this.productSet);
			} else {
				productTileChoose({
					productSet: {
						products: [this.productSet],
						pricing: {
							priceAfterDiscounts: this.packagePrice
						}
					},
					tilePosition: this.tilePosition
				});

				noDataEvent('productTileChooseWpk');
			}

			this.trackProductAdded({
				position: this.tilePosition,
				sort: this.sortBy,
				filteredBy: this.filteredBy
			}, true);

			this.$router.push({
				path: '/app/schedule',
				query: extend(this.query, {
					localBest: this.productSet.localBestSeller || false,
					qty: this.qtySelected.length ? this.qtySelected.join(',') : this.defaultQty,
					sku: this.productSet.length > 1 ? this.productSet.map(prod => prod.sku).join() : this.productSet.sku
				})
			});
		}
	},

	computed: {
		...mapState('modal', ['oos']),
		...mapState('content/products', ['details', 'packageBreakdown']),
		...mapState('dealer', ['selected']),
		...mapState('products', ['defaultQty', 'qtySelected', 'deliveryDate']),
		...mapGetters('productResults', ['filterToShow', 'sortBy']),
		isDisabled() {
			// Disable the button if there is 0 qty for front and rear
			return !this.locQty.rear && !this.locQty;
		},
		suggestedRetailPrice() {
			return this.$t('message.suggestedRetailPrice');
		},
		isStaggered() {
			if (this.productSet.products) {
				return this.productSet.products.length > 1;
			}

			return false;
		},
		packagePrice() {
			return packagePrices[this.productSet.sku] || null;
		},
		packagePriceEach() {
			return this.packagePrice ? (this.packagePrice.installPrice / 4) : null;
		},
		// eslint-disable-next-line complexity
		tireMessage() {
			let setNumInstalled = '';
			let totalQty = this.qtySelected.length ? this.qtySelected.reduce((total, q) => total + q) : this.defaultQty;

			switch (totalQty) {
				case 4:
					setNumInstalled = 'message.setOfFour';
					break;
				case 3:
					setNumInstalled = 'message.setOfThree';
					break;
				case 2:
					setNumInstalled = 'message.setOfTwo';
					break;
				case 1:
					setNumInstalled = '1';
					break;
				default:
					setNumInstalled = 'message.setOfFour';
					break;
			}

			return setNumInstalled;
		},
		tireQuantity() {
			if (!this.locQty.rear) {
                return parseInt(this.locQty.front); //eslint-disable-line
			}

            return parseInt(this.locQty.front + this.locQty.rear); //eslint-disable-line
		}
	},

    created() { //eslint-disable-line
		const [front, rear] = isEmpty(this.qtySelected) ? this.defaultQty : this.qtySelected;

		this.locQty = {
			front: this.isStaggered ? front || 0 : front,
			rear: this.isStaggered ? rear || 0 : rear
		};
	}
};
</script>
