const { last } = require('underscore');

function serviceReducer(service) { //eslint-disable-line

	const allowedServices = [
		'oil change',
		'alignment',
		'permasafe',
		'nissan clean',
		'nissan shield',
		'infiniti clean',
		'infiniti shield',
		'mobile installation service'
	];

	if (service) {
		const title = service.title.toLowerCase();

		return service.isPromoted && allowedServices.some(allowedService => title.indexOf(allowedService) >= 0) ? service : null;

	}

	return null;

}
function priceFix(service) {
	let price = service ? service.price : null;

	if (price && price.length <= 2) {
		service.price = Number(price).toFixed(2);
	}

	return service;
}
const allSeason = prods =>
	prods.filter(({ products }) => last(products).season.toLowerCase() === 'all season');

const summer = prods =>
	prods.filter(({ products }) => last(products).season.toLowerCase() === 'summer');

const fuelEfficient = prods =>
	prods.filter(({ products }) => last(products).ecoTire);

const winter = prods =>
	prods.filter(({ products }) => last(products).winter);

const recommended = prods =>
	prods.filter(product => product.recommended);

const promos = prods =>
	prods.filter(({ products }) => last(products).promotions.length > 0);

const oem = prods =>
	prods.filter(({ products }) => last(products).factoryInstalled);

const mobileinstall = prods =>
	prods.filter(({ products }) => products.every((product) => product.isAvailableFacingWarehouse === true));

const filterServices = services => {
	return services.filter(service => priceFix(serviceReducer(service)));
};


module.exports = {
	allSeason,
	summer,
	fuelEfficient,
	winter,
	recommended,
	promos,
	oem,
	filterServices,
	mobileinstall
};
