<template>
    <div class="row">
        <div class="col-12">
            <div v-html="formLocation"></div>
        </div>

        <section class="col-12">
            <no-dealer-form :query="query" />
        </section>
    </div>
</template>

<script>
import noDealerForm from '@/components/NoDealerForm.vue';
import { mapState, mapActions } from 'vuex';
import { RudderstackChatMixin } from '@/rudderstack/track/mixins/chat';

export default {
	// Pass query string in as properties
	props: {
		query: {
			type: Object,
			required: true
		}
	},
	mixins: [RudderstackChatMixin],
	components: {
		noDealerForm
	},
	computed: {
		...mapState('content/noDealer', ['formLocation']),
		...mapState('consumerInfo', ['userLanguage']),
		...mapState('search', ['dealership_id'])
	},
	methods: {
		...mapActions('content/noDealer', ['fetchContent'])
	},
	watch: {
		'$store.state.consumerInfo.userLanguage': function() {
			// true fed to fetchContent to trigger re-fetching of content if userLanguage is changed
			this.fetchContent(true);
		}
	},
	created() {
		this.fetchContent();
		this.$rudderstack.page(this.$route.meta.category, this.$route.meta.name, {
			...this.$route.query,
			'dealership_id': this.dealership_id
		});
	}
};
</script>
