<template>
    <div class="col-12">
        <div v-html="formLocationConfirmation"></div>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import { RudderstackChatMixin } from '@/rudderstack/track/mixins/chat';

export default {
	props: {
		query: {
			type: Object,
			required: true
		}
	},
	mixins: [RudderstackChatMixin],
	computed: {
		...mapState('content/noDealer', [
			'formLocationConfirmation'
		]),
		...mapState('search', ['dealership_id'])
	},
	created() {
		this.$rudderstack.page(this.$route.meta.category, this.$route.meta.name, {
			...this.$route.query,
			'dealership_id': this.dealership_id
		});
	}
};
</script>
