const config = require('../../config/program-config');

module.exports = requestHost => { // eslint-disable-line
	const themes = Object.keys(config);
	let matchingTheme = '';

	themes.forEach(theme => { //eslint-disable-line
		if (requestHost && (requestHost.includes(config[theme].hostName) || requestHost.includes(config[theme].hostName2)) && !matchingTheme) {
			matchingTheme = config[theme];
		}
	});

	if (matchingTheme) {
		return matchingTheme;
	}

	return config['ddr'];

};
