<template>
    <div class="receipt">
        <dialog-modal
            :isOpen="isSurveyShowing"
            class="receipt-survey"
            @close="handleSurveyClose"
            :ariaLabel="$t('message.surveyTitle')"
        >
            <h4 slot="header">
                {{ $t("message.surveyTitle") }}
            </h4>
            <p>
                {{ $t("message.surveyBody") }}
            </p>
            <a
                slot="footer"
                target="_blank"
                :href="typeformSurveyURL"
                class="button button--primary receipt-survey__link"
                @click="handleSurveyClose"
            >
                {{ $t("message.surveyButtonText") }}
            </a>
        </dialog-modal>

        <div v-if="isRefresh">
            <h2>{{ $t("message.orderConfirmation") }}</h2>
        </div>
        <div v-else>
            <div class="row">
                <!-- Next Steps. -->
                <div class="col-12">
                    <div class="row">
                        <div class="col-12">
                            <div class="header__page-heading" v-html="headline"></div>
                        </div>
                        <!-- Content -->
                        <template v-if="!isMobileInstallation && !receipt.consumer.selectedFinancing">
                            <div class="col-12 col-md-8" v-html="primary"></div>
                            <div class="col-12 col-md-4" v-html="secondary"></div>
                        </template>

                        <template v-if="isMobileInstallation">
                            <div class="col-12 col-md-8" v-html="mobileInstallPrimary"></div>
                            <div class="col-12 col-md-4" v-html="mobileInstallSecondary"></div>
                        </template>

                        <div v-if="receipt.consumer.selectedFinancing" class="col-12 receipt__financing-header">
                            <h6>{{ $t("message.thankyouShopping") }}</h6>
                            <p v-html="receiptPageText"></p>
                            <img v-if="!isCreditCard" :src="partnerLogoURL" :alt="partnerLogoName" />
                        </div>
                    </div>
                </div>

                <!-- Billing Summary -->
                <section class="col-12 col-md-4 receipt__section">
                    <div class="row bill-summary">
                        <h2 class="col-12 bill-summary__header">{{ $t("message.yourOrder") }}</h2>
                        <div class="col-12">
                            <table class="bill-summary__table">
                                <tbody>
                                    <!-- Order Number -->
                                    <tr>
                                        <td
                                            class="bill-summary__cell bill-summary__cell--left"
                                        >{{ $t("message.order") }} {{ $t("message.no") }}.</td>
                                        <td class="bill-summary__cell bill-summary__cell--right">
                                            <strong>{{receipt.leadID}}</strong>
                                        </td>
                                    </tr>
                                    <!-- Amount -->
                                    <tr>
                                        <td
                                            class="bill-summary__cell bill-summary__cell--left"
                                        >{{ $t("message.amount") }}*</td>
                                        <td class="bill-summary__cell bill-summary__cell--right">
                                            <strong>{{ $n(receipt.payment.grandTotalAmount , 'currency')}}</strong>
                                        </td>
                                    </tr>
                                    <!-- Credit Card -->
                                    <tr v-if="receipt.hasCreditCardCheckout">
                                        <td
                                            class="bill-summary__cell bill-summary__cell--left fs-mask"
                                        >{{ $t("message.creditCard") }}</td>
                                        <td class="bill-summary__cell bill-summary__cell--right fs-mask">
                                            <strong>{{ $t("message.cardEndingIn") }}{{ccNumber}}</strong>
                                            <br />
                                            <small>{{ $t("message.notCharged") }}.</small>
                                        </td>
                                    </tr>
                                    <!-- Billing Address -->
                                    <tr>
                                        <td class="bill-summary__cell bill-summary__cell--left fs-mask">
                                            <span v-if="receipt.hasCreditCardCheckout">{{ $t("message.billing") }}</span>
                                            {{ $t("message.address") }}
                                        </td>
                                        <td class="bill-summary__cell bill-summary__cell--right  fs-mask">
                                            <strong>
                                                {{receipt.consumer.address.line1}}
                                                <br />
                                                {{receipt.consumer.address.city}}, {{receipt.consumer.address.state}}
                                                {{receipt.consumer.address.zipCode}}
                                            </strong>
                                            <br />
                                        </td>
                                    </tr>
                                    <!-- Email -->
                                    <tr>
                                        <td
                                            class="bill-summary__cell bill-summary__cell--left fs-mask"
                                        >{{ $t("message.emailSingle") }}</td>
                                        <td class="bill-summary__cell bill-summary__cell--right  fs-mask">
                                            <strong>{{receipt.consumer.email}}</strong>
                                            <br />
                                            <small>{{ $t("message.yourPrivacyIsImportant") }}</small>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </section>

                <!-- Appointment -->
                <section class="col-12 col-md-4 receipt__section">
                    <div class="row appointment">
                        <h2
                            class="col-12 appointment__header appointment__header--large appointment__header--extra-margin"
                        >{{ $t("message.appointment") }}</h2>
                        <div class="col-12">
                            <table class="appointment__table">
                                <tbody>
                                    <tr>
                                        <td class="appointment__cell appointment__cell--left">{{ $t("message.when") }}</td>
                                        <td class="appointment__cell appointment__cell--right">
                                            <strong>
                                                {{receipt.selectedTime ? $d(new Date(receipt.selectedTime), 'time') : ''}}
                                                {{receipt.selectedTime ? $d(new Date(receipt.selectedTime), 'long') : ''}}
                                            </strong>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="appointment__cell appointment__cell--left fs-mask">{{ $t("message.where") }}</td>
                                        <td class="appointment__cell appointment__cell--right fs-mask" v-if="!isMobileInstallation">
                                            <strong>{{receipt.dealer.dealershipname}}</strong>
                                            <br />
                                            {{receipt.dealer.address}}
                                            <br />
                                            {{receipt.dealer.city}}, {{receipt.dealer.state}} {{receipt.dealer.zip}}
                                            <br />
                                            {{receipt.dealer.phoneNumber}}
                                            <br />
                                        </td>
                                        <td class="appointment__cell appointment__cell--right fs-mask" v-if="isMobileInstallation">
                                            {{receipt.consumer.deliveryAddress.line1}}
                                            <br />
                                            {{receipt.consumer.deliveryAddress.city}},
                                            {{receipt.consumer.deliveryAddress.state}} {{receipt.consumer.deliveryAddress.zipCode}}
                                            <br />
                                        </td>
                                    </tr>
                                    <tr v-if="receipt.leadSearchType.toLowerCase() === 'searchbyvehicle'">
                                        <td class="appointment__cell appointment__cell--left fs-mask">{{ $t("message.for") }}</td>
                                        <td
                                            class="appointment__cell appointment__cell--right fs-mask"
                                        >
                                            <strong>{{receipt.vehicle.year}} {{receipt.vehicle.make}} {{receipt.vehicle.model}}</strong>
                                            <br />
                                            {{ $t("message.trim") }}: {{receipt.vehicle.trim}}
                                            <br />
                                            {{ $t("message.option") }}: {{receipt.vehicle.option}}
                                        </td>
                                    </tr>
                                    <tr v-if="isMobileInstallation">
                                        <td class="appointment__cell appointment__cell--left fs-mask">{{ $t("message.onBehalfOf") }}</td>
                                        <td class="appointment__cell appointment__cell--right fs-mask">
                                            <strong>{{receipt.dealer.dealershipname}}</strong>
                                            <br />
                                            {{receipt.dealer.address}}
                                            <br />
                                            {{receipt.dealer.city}}, {{receipt.dealer.state}} {{receipt.dealer.zip}}
                                            <br />
                                            {{receipt.dealer.phoneNumber}}
                                            <br />
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </section>

                <!-- Product Summary -->
                <section class="col-12 col-md-4 receipt__section">
                    <div class="row product-summary">
                        <h2 class="col-12 product-summary__header">{{ $t("message.yourTires") }}</h2>
                        <div class="col-12">
                            <table class="product-summary__table">
                                <tbody>
                                    <tr>
                                        <td class="product-summary__cell appointment__cell--left">{{ $t("message.qty") }}</td>
                                        <td class="product-summary__cell appointment__cell--right">
                                            <strong>{{qty}}</strong>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td
                                            class="product-summary__cell product-summary__cell--left"
                                        >{{ $t("message.what") }}</td>
                                        <td class="product-summary__cell product-summary__cell--right">
                                            <strong>{{productNames}}</strong>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td
                                            class="product-summary__cell product-summary__cell--left"
                                        >{{ $t("message.size") }}</td>
                                        <td class="product-summary__cell product-summary__cell--right">
                                            <strong>{{tireSizes}}</strong>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td
                                            class="product-summary__cell product-summary__cell--left"
                                        >{{ $t("message.serviceDesc") }}</td>
                                        <td class="product-summary__cell product-summary__cell--right">
                                            <strong>{{serviceDesc}}</strong>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td
                                            class="product-summary__cell product-summary__cell--left"
                                        >{{ $t("message.inc") }}</td>
                                        <td class="product-summary__cell product-summary__cell--right">
                                            <ul class="product-summary__list" v-if="receipt.products.length">
                                                <li
                                                    v-for="(include, includesIndex) in includes"
                                                    :key="includesIndex"
                                                >{{include}}</li>
                                            </ul>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <small
                                v-if="receipt.leadSearchType.toLowerCase() === 'searchbytiresize'"
                            >{{ $t("message.bySizeReceiptDisclaimer") }}</small>
                        </div>
                    </div>
                </section>
            </div>

            <div class="row">
                <div class="col-md-4 col-12">
                    <button
                        @click="runPrint"
                        class="bill-summary--button button button--primary button--full"
                    >{{ $t("message.printOrderConfirmation") }}</button>
                </div>
            </div>

            <div v-if="receipt.consumer.selectedFinancing">
                <p class="finance-disclaimer" v-html="disclaimer"></p>
            </div>

            <div class="row" v-html="policy"></div>
            <!-- Tax disclaimer in content-ad -->
            <!-- <div class="row" v-html="ad"></div> -->
            <!-- REPLACE CONTENT AD -->
            <div class="container">
                <div class="block block__contentBlock " style="">
                    <div class="col ">
                        <p style="font-size:10px;"><sup>*</sup>{{ taxDisclaimer }}</p>
                    </div>
                </div>
            </div>

        </div>
    </div>
</template>

<script>

import axios from 'axios';

import { getStateFromSession } from '@/utils/receipt-session';
import { last, filter } from 'underscore';
import { mapActions, mapMutations, mapState } from 'vuex';
import { consumerReceiptGenerated } from '@/utils/setDataLayer';
import DealerHeader from '@/components/DealerHeader.vue';
import DialogModal from '@/components/DialogModal';
import { RudderstackReceiptMixin } from '@/rudderstack/track/mixins/receipt';
import { RudderstackChatMixin } from '@/rudderstack/track/mixins/chat';

export default {
	title: 'Receipt',

	props: {
		query: {
			type: Object,
			required: true
		}
	},

	mixins: [RudderstackReceiptMixin, RudderstackChatMixin],

	components: {
		DealerHeader,
		DialogModal
	},

	data() {
		// This object is filled by a fetch to session, so make sure it has empty sub objects where vue will try to access it.
		// A simple sub object will throw a (admittedly harmless) js error when vue does something like
		// Receipt.leadSearchType.ToLowerCase()
		return {
			isRefresh: false,
			cid: false,
			receipt: {
				leadSearchType: '',
				qtySelected: [],
				payment: {},
				products: [],
				consumer: {
					address: {},
					deliveryAddress: {}
				},
				dealer: {},
				vehicle: {},
				leadID: '',
				additionalServices: [],
				appointmentType: {}
			},
			showSurveyModal: true
		};
	},

	methods: {
		...mapActions('content/receipt', ['fetchContent']),
		...mapActions('productDetail', ['setWarrantyDoc']),
		...mapMutations('dealer', ['dealerFromSession']),
		...mapMutations('consumerInfo', ['setUserLanguage']),
		setDataLayer() {

			consumerReceiptGenerated({
				leadNumber: this.receipt.leadID,
				finalPriceTotal: this.receipt.payment.grandTotalAmount,
				additionalServices: this.receipt.additionalServices,
				appointmentType: this.receipt.appointmentType.name,
				referrerType: (this.query.referrer ? this.query.referrer : 'Non-referral'),
				qty: this.qty,
				isStaggered: this.isStaggered,
				isWheelPackage: this.isWheelPackage
			});
		},
		runPrint() {
			window.print();
		},
		// eslint-disable-next-line complexity
		async runRefresh() {
			// during refresh
			if (this.receipt.leadID === '') {
				const orderNo = sessionStorage.getItem('orderNo');

				const response = await axios.get(`/service/consumerlead?orderNumber=${orderNo}`);

				// if (response['data']['appointment']['apptID'] !== '' && response['data']['repairOrderNumber'] !== '' && response['data']['productGroups'].length > 0) {
				if (response.data.appointment.apptID !== '' && response.data.repairOrderNumber !== '' && response.data.productGroups.length > 0) {
					this.isRefresh = true;
				}

			}
		},
		handleSurveyClose() {
			this.showSurveyModal = false;
			sessionStorage.setItem('hasSeenSurvey', true);
		}
	},

	computed: {
		...mapState('content/receipt',
			['primary', 'secondary', 'policy', 'headline', 'mobileInstallPrimary', 'mobileInstallSecondary']),
		...mapState('productDetail', ['warrantyDoc', 'warrantyDocTitle']),
		...mapState('initial', ['typeformSurveyURL', 'title']),
		...mapState('search', ['dealership_id']),
		...mapState('dealer', ['selected']),

		newPageTitle() {
			// eslint-disable-next-line prefer-template
			const page = this.$t('message.receiptPage') + ' | ' + this.title;

			return page;
		},
		isSurveyShowing() {
			return this.typeformSurveyURL !== '' && this.showSurveyModal;
		},
		qty() {
			// Add the total numbers of tires
			return this.receipt.qtySelected.reduce((acc, qty) => {
				return acc + qty;
			}, 0);
		},
		isCreditCard() {
			if (this.receipt.dealer.financePartner) {
				if (this.receipt.dealer.financePartner.disclaimerText2) {
					return true;
				}
			}

			return false;
		},
		partnerLogoName() {
			if (this.receipt.dealer.financePartner) {
				return this.receipt.dealer.financePartner.description;
			}

			return `finance partner logo`;
		},
		isMobileInstallation() {
			return this.receipt.appointmentType.id === 13;
		},
		partnerLogoURL() {
			if (this.receipt.dealer.financePartner && this.receipt.dealer.financePartnerCode) {
				return `/dist/assets/finance/${this.receipt.dealer.financePartner.code}_logo.svg`.toLowerCase();
			}

			return null;
		},
		receiptPageText() {
			if (!this.receipt.dealer.financePartner) {
				return null;
			}

			return this.receipt.dealer.financePartner.receiptPageText;
		},
		disclaimer() {
			if (!this.receipt.dealer.financePartner) {
				return null;
			}

			return `<sup>&dagger;</sup>${this.receipt.dealer.financePartner.disclaimer}`;
		},
		promotions() {
			return filter(this.receipt.promotions, promotion => {
				return promotion.type !== 'rebate';
			});
		},
		ccNumber() {
			const { subscriptionID } = this.receipt.payment;

			return subscriptionID.slice(-4);
		},
		tireSizes() {
			const [front, rear] = this.receipt.products;

			if (rear) {
				return `${front.size}, ${rear.size}`;
			}
			if (front) {
				return front.size;
			}

			return '';
		},
		serviceDesc() {
			const [front, rear] = this.receipt.products;

			if (rear) {
				return `${front.serviceDescription}, ${rear.serviceDescription}`;
			}
			if (front) {
				return front.serviceDescription;
			}

			return '';
		},
		productNames() {
			const [front, rear] = this.receipt.products;


			if (rear) {
				return `${front.brand} ${front.catalogEntryDescription}, ${rear.catalogEntryDescription}`;
			}
			if (front) {
				return `${front.brand} ${front.catalogEntryDescription}`;
			}

			return '';
		},
		isStaggered() {
			if (this.receipt.products) {
				return this.receipt.products.length > 1;
			}

			return false;
		},
		isWheelPackage() {
			return this.receipt.products[0].programCategory.toUpperCase() === 'WPK';
		},
		includes() {
			if (this.isWheelPackage) {
				const wpki = [
					this.$t('message.wheel'),
					this.$t('message.tire'),
					this.$t('message.centerCap'),
					this.$t('message.tpms')
				];

				return wpki;

			}
			const aioIncludes = [
				this.$t('message.tires'),
				this.$t('message.mountingBalancingInstallation'),
				this.$t('message.disposalFees'),
				this.$t('message.shopSupplies')
			];

			if (this.receipt.products.length && last(this.receipt.products).showRoadHazard) {
				aioIncludes.push('Road Hazard Coverage');
			}

			return aioIncludes;
		},
		taxDisclaimer() {
			if (this.isMobileInstallation) {
				return this.$t('message.taxDisclaimerMobileInstall');
			}

			return this.$t('message.taxDisclaimer');
		}
	},

	mounted() {
		document.title = this.newPageTitle;

		if (sessionStorage.getItem('hasSeenSurvey')) {
			this.showSurveyModal = false;
		}
	},

	async created() {
		if (sessionStorage.getItem('orderNo')) {
			this.runRefresh();
		}

		const { uid } = this.query;

		const { receipt } = await getStateFromSession(uid);

		// On page reload, call the API to get receipt details. Or else, store the order no for future use
		const storedLocale = window.localStorage.getItem('userLocale');

		if (storedLocale) {
			this.$i18n.locale = storedLocale;
			this.setUserLanguage(this.$i18n.locale);
		}

		await this.dealerFromSession(receipt.dealer.dealershipname);
		this.receipt = receipt;

		sessionStorage.setItem('orderNo', this.receipt.leadID);

		await this.fetchContent();

		if (window.dataLayer) {
			window.dataLayer.push({'receipt': this.receipt});
		}

		await this.setDataLayer();

		this.$rudderstack.page(this.$route.meta.category, this.$route.meta.name, {
			...this.$route.query,
			'dealership_id': this.receipt.dealer.dealershipid
		});

		this.trackOrderCompleted();

		return axios.delete('/service/session');

	}
};
</script>
